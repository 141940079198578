Vue.component('loader', {
  template: `
  <div class="loader" v-if="active">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
  </div>
  `,
  data: function () {
    return {}
  },
  props: [
    'active'
  ],
})